<template>
  <div>
    <div class="page-title dashboard">
      <div class="container">
        <div class="row pb-3">
          <div class="col-6">
            <div class="page-title-content">
              <p>
                Welcome Back,
                <!-- <span class="text-capitalize">{{ name }}</span> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="card sub-menu">
              <div class="card-body">
                <ul class="d-flex">
                  <li
                    v-for="link in links"
                    :key="link.to"
                    class="nav-item"
                    :class="$route.name === link.title ? 'active' : null"
                  >
                    <router-link :to="link.to" class="nav-link">
                      <i :class="link.icon"></i>
                      <span>{{ link.text }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Linked Crypto Wallet  </h4>
              </div>
              <div class="card-body">
                <div v-show="loading" class="card__loader"></div>
                <div class="table-responsive">
                  <table class="table linked_account">
                    <tbody>
                      <tr
                        v-for="wallet in wallets"
                        :key="wallet.id"
                      >
                        <td>
                          <div class="media">
                            <span class="mr-3"><i class="fa fa-btc"></i></span>
                            <div class="media-body">
                              <h5 class="mt-0 mb-1">{{ wallet.description }}</h5>
                              <p>{{ wallet.address }}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="edit-option">
                            <a href="javascript:void(0)" @click.prevent="deleteWallet(wallet)"><i class="fa fa-trash"></i></a>
                          </div>
                        </td>
                        <td>
                          <div class="verify">
                            <div class="verified">
                              <span><i class="la la-check"></i></span>
                              <a href="#">Verified</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <router-link
                      to="add-crypto-address"
                      class="btn btn-primary px-4 py-2 mr-3 my-2"
                      >Add New Wallet
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import client from '@/api/client';

export default {
  data: () => ({
    wallets: [],
    links: [
      {
        to: 'profile',
        title: 'Profile',
        icon: 'mdi mdi-bullseye',
        text: 'Account Overview',
      },
      /* {
        to: 'settings-security',
        title: 'SettingsSecurity',
        icon: 'mdi mdi-lock',
        text: 'Security Details',
      }, */
      {
        to: 'settings-account',
        title: 'SettingsAccount',
        icon: 'mdi mdi-bank',
        text: 'Linked Bank Accounts',
      },
      {
        to: 'settings-crypto',
        title: 'SettingsCrypto',
        icon: 'mdi mdi-bank',
        text: 'Linked Crypto Wallets',
      },
      {
        to: 'settings',
        title: 'VipApplication',
        icon: 'mdi mdi-lock',
        text: 'VIP Application',
      },
    ],
    loading: false,
  }),
  computed: {
    name() {
      const data = client.token.id.data;
      return `${data.user_fname} ${data.user_lname}`;
    }
  },
  mounted() {
    this.getWallets();
  },
  methods: {
    getWallets() {
      this.loading = true;
      api.wallet.read().then((res) => {
        this.loading = false;
        this.wallets = res;
        console.log(res);
      }).catch((err) => {
        this.loading = false;
        this.$toast.error('Something went wrong, please refresh');
      });
    },
    deleteWallet(wallet) {
      this.$toast.clear();
      const canDelete = confirm('Are you sure you want to delete this wallet');
      if (canDelete) {
        this.loading = true;
        api.wallet.delete(wallet.id).then((res) => {
          this.$toast.success('Wallet deleted successfully');
          this.getWallets();
        }).catch((err) => {
          this.$toast.error(err);
          this.loading = false;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@keyframes load {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(500%);
  }
}
.card-body {
  position: relative;
  .card__loader {
    width: 100%;
    height: 2px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    &::after {
      content: '';
      width: 20%;
      height: 100%;
      display: block;
      background-color: var(--primary);
      animation: load .8s linear infinite;
    }
  }
}
.card-profile__info h5 {
  display: inline-block;
}

.sub-menu {
  ul {
    @include screen('phone-land') {
      display: inline-block !important;
    }

    li {
      a {
        padding: 0px 12px;
        display: flex;
        align-items: center;
        color: var(--body-color);

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: var(--primary);
          background-color: transparent;
        }
      }

      &.active {
        a {
          color: var(--primary);
        }
      }

      i {
        font-weight: bold;
        margin-right: 7px;
        font-size: 20px;
      }

      span {
        font-weight: 400;
      }
    }
  }
}

.phone_verify {
  margin-top: 50px;
  border-top: 1px solid var(--border-color);
  padding-top: 30px;
}

.phone_verified {
  display: flex;
  align-items: center;

  h5 {
    margin-bottom: 0;
    margin-right: 30px;
    color: var(--body-color);

    span {
      // background: var(--primary);
      color: var(--primary);
      padding: 9px 10px;
      border-radius: 50px;
      margin-right: 7px;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid var(--border-color);
      height: 40px;
      width: 40px;
      display: inline-block;
    }
  }

  .verified,
  .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;

    span {
      background: var(--primary);
      color: var(--white);
      padding: 10px;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 15px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .not-verify {
    span {
      background: #e50202;
    }
  }
}

// Security
.id_card {
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 20px 0px;
}

table {
  color: var(--text);
}

.dashboard {
  margin-left: 0;
}
</style>
